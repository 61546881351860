import React, { forwardRef } from 'react';
import Row from './Row';

interface Centered {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const Centered: React.ForwardRefRenderFunction<HTMLDivElement, Centered> = (props, ref) => {
    const { className, children } = props;

    return (
        <Row ref={ref} className={className} alignItems="center" justifyContent="center">
            {children}
        </Row>
    );
};

export default forwardRef(Centered);
