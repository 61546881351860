import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface Row {
    className?: string;
    alignItems?: 'flex-start' | 'center' | 'flex-end';
    justifyContent?: 'flex-start' | 'center' | 'row';
    children?: React.ReactNode | React.ReactNodeArray;
}

const Row: React.ForwardRefRenderFunction<HTMLDivElement, Row> = (props, ref) => {
    const { className, alignItems = 'flex-start', justifyContent = 'flex-start', children } = props;

    return (
        <RowStyled
            ref={ref}
            className={className}
            alignItems={alignItems}
            justifyContent={justifyContent}
        >
            {children}
        </RowStyled>
    );
};

const RowStyled = styled.div<Row>`
    display: flex;

    ${({ justifyContent, alignItems }) => css`
        justify-content: ${justifyContent};
        align-items: ${alignItems};
    `}
`;

export default forwardRef(Row);
