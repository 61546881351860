import { useEffect } from 'react';

interface IUseDetectClickOutside {
    elements: HTMLElement[];
    selectors?: string[];
    enabled: boolean;
    mobile?: boolean;
    swallowClicks?: boolean;
    escapeKey?: boolean;
    onClickOutside: () => void;
}

const useDetectClickOutside = ({
    elements,
    enabled,
    selectors = [],
    mobile = false,
    swallowClicks = true,
    escapeKey = false,
    onClickOutside,
}: IUseDetectClickOutside) => {
    useEffect(() => {
        if (enabled) {
            const clickEvent = mobile ? 'touchstart' : 'click';
            const handleClick = (e: MouseEvent | TouchEvent) => {
                const allElements = [...elements];

                selectors.forEach((selector) => {
                    const element = document.querySelector(selector);
                    if (element) {
                        allElements.push(element as HTMLElement);
                    }
                });

                if (
                    !allElements.some(
                        (element) => !!element && element.contains(e.target as HTMLElement)
                    )
                ) {
                    if (swallowClicks) {
                        e.stopPropagation();
                        e.preventDefault();
                    }

                    window.removeEventListener(clickEvent, handleClick, { capture: true });
                    onClickOutside();
                }
            };

            window.addEventListener(clickEvent, handleClick, { capture: true });

            return () => {
                window.removeEventListener(clickEvent, handleClick, { capture: true });
            };
        }
    }, [enabled, mobile, swallowClicks, onClickOutside, ...elements, ...selectors]);

    useEffect(() => {
        if (enabled && escapeKey) {
            const handleEscape = (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    onClickOutside();

                    if (swallowClicks) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }
            };

            document.addEventListener('keydown', handleEscape, { capture: true });

            return () => {
                document.removeEventListener('keydown', handleEscape, { capture: true });
            };
        }
    }, [enabled, escapeKey, swallowClicks, onClickOutside]);
};

export default useDetectClickOutside;
