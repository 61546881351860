import styled, { css } from 'styled-components';
import { rem } from 'polished';

const HoverStyles = css`
    text-decoration: underline;
    text-underline-offset: ${rem(4)};
    color: ${({ theme }) => theme.palette.White};

    svg {
        color: ${({ theme }) => theme.palette.White};
    }
`;

const TextButton = styled.button<{ format?: 'default' | 'secondary' }>`
    ${({ theme }) => theme.typography.FontMedium}
    font-size: ${rem(13)};
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme, format }) =>
        format === 'secondary' ? theme.palette.LightGrey1 : theme.palette.White};
    transition: color 50ms ease;

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-right: ${rem(4)};
        color: ${({ theme, format }) =>
            format === 'secondary' ? theme.palette.LightGrey1 : theme.palette.White};
        transition: fill 50ms ease;
    }

    &.focus-visible {
        ${HoverStyles}
    }

    @media (hover: hover) {
        &:hover {
            ${HoverStyles}
        }
    }
`;

export default TextButton;
