import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Pulse from 'packages/common/base/components/Pulse';
import { transparentize } from 'polished';

interface ProductRoadmap {
    className?: string;
}

const ProductRoadmap: React.FunctionComponent<ProductRoadmap> = (props) => {
    const { className } = props;

    return (
        <ProductRoadmapStyled className={className}>
            <SectionStyled>
                <Section_HeadingStyled>
                    <Pulse format="error" />
                    <Section_TitleStyled>NEXT UP</Section_TitleStyled>
                </Section_HeadingStyled>
                <Section_FeaturesStyled>
                    <Feature
                        heading="Android App"
                        description="Mobile app for Android users launching soon!"
                    />
                </Section_FeaturesStyled>
            </SectionStyled>
            <SectionStyled>
                <Section_HeadingStyled>
                    <Pulse format="error" />
                    <Section_TitleStyled>SUMMER 2022</Section_TitleStyled>
                </Section_HeadingStyled>
                <Section_FeaturesStyled>
                    <Feature
                        heading="MLB regional TV support"
                        description="Watch local and nationally televised MLB games."
                    />
                    <Feature
                        heading="Additional key streaming provider support"
                        description="Connect additional streaming services including ESPN+, Paramount+ and Peacock."
                    />
                    <Feature
                        heading="Shareable room clips"
                        description="Easily grab and share clips from any watch party in real time."
                    />
                    <Feature
                        heading="Member roles & permissions"
                        description="Create and assign user roles, permissions and access levels."
                    />
                </Section_FeaturesStyled>
            </SectionStyled>
            <SectionStyled>
                <Section_HeadingStyled>
                    <Pulse format="error" />
                    <Section_TitleStyled>LATE 2022</Section_TitleStyled>
                </Section_HeadingStyled>
                <Section_FeaturesStyled>
                    <Feature
                        heading="Smart TV apps"
                        description="Watch Playback on your smart TV, and split streaming and chat across your devices!"
                    />
                    <Feature
                        heading="Creator profile pages & room personalization"
                        description="Personalize your room with custom branding, emojis, etc."
                    />
                    <Feature
                        heading="Creator studio tools"
                        description="Unlock aadditional in-room functionality and analytics with screensharing, telestrator, real-time metrics, and more."
                    />
                </Section_FeaturesStyled>
            </SectionStyled>
            <SectionStyled>
                <Section_HeadingStyled>
                    <Pulse format="error" />
                    <Section_TitleStyled>2023 TARGETS</Section_TitleStyled>
                </Section_HeadingStyled>
                <Section_FeaturesStyled>
                    <Feature
                        heading="Room discovery and recommendations"
                        description="Users can browse active watch parties and discover featured rooms and tailored recommendations."
                    />
                    <Feature
                        heading="Creator monetization"
                        description="Community members can subscribe to rooms for additional features and content."
                    />
                    <Feature
                        heading="iOS and Android apps available in app stores"
                        description="Official launch of the Playback apps in all major app stores."
                    />
                    <Feature
                        heading="Unlimited room capacity"
                        description="Creators can host for an unlimited amount of viewers."
                    />
                    <Feature
                        heading="Sports gaming integrations"
                        description="Direct integrations with major fantasy and gaming platforms."
                    />
                </Section_FeaturesStyled>
            </SectionStyled>
        </ProductRoadmapStyled>
    );
};

interface Feature {
    className?: string;
    heading: string;
    description: string;
}

const Feature: React.FC<Feature> = ({ className, heading, description }) => {
    return (
        <FeatureStyled className={className}>
            <Feature_HeadingStyled>{heading}</Feature_HeadingStyled>
            <Feature_DescriptionStyled>{description}</Feature_DescriptionStyled>
        </FeatureStyled>
    );
};

const Feature_DescriptionStyled = styled.p`
    font-size: ${rem(16)};
    color: ${({ theme }) => transparentize(0.6, theme.palette.Pink)};
    line-height: 120%;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(20)};
    }
`;

const Feature_HeadingStyled = styled.h3`
    ${({ theme }) => theme.typography.Heading16}
    margin-bottom: ${rem(8)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Heading20}
        margin-bottom: ${rem(12)};
    }
`;

const FeatureStyled = styled.div`
    border-radius: ${rem(12)};
    padding: ${rem(12)} ${rem(16)};
    background-color: rgba(0, 0, 0, 0.2);
    text-align: left;

    & + & {
        margin-top: ${rem(12)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(16)} ${rem(24)};
    }
`;

const Section_FeaturesStyled = styled.div`
    padding: ${rem(10)} 0 ${rem(10)} ${rem(24)};
    border-left: ${rem(2)} solid rgba(0, 0, 0, 0.2);
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: ${rem(2)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Heading20}
        margin-bottom: ${rem(12)};
    }
`;

const Section_TitleStyled = styled.h2`
    ${({ theme }) => theme.typography.Heading13}
    ${({ theme }) => theme.typography.FontBold}
    color: ${({ theme }) => theme.palette.Red};
    margin-left: ${rem(24)};
`;

const Section_HeadingStyled = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${rem(12)};
    margin-left: ${rem(-4)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(20)};
    }
`;

const SectionStyled = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: ${rem(32)};
    }
`;

const ProductRoadmapStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export default ProductRoadmap;
