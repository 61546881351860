import { css } from 'styled-components';

const DEFAULT_DURATION = 200;

const FadeOverlayStyles = css<{ visible: boolean; duration?: number; slide?: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    z-index: 10;

    ${({ duration = DEFAULT_DURATION, slide }) => css`
        transition: opacity ${duration}ms ease-out, visibility 0ms linear ${duration}ms;

        ${slide &&
        css`
            transform: translateX(-100%);
            transition: opacity ${duration}ms ease-out, transform ${duration}ms ease-out,
                visibility 0ms linear ${duration}ms;
        `}
    `}

    ${({ visible, duration = DEFAULT_DURATION, slide }) =>
        visible &&
        css`
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
            transition: opacity ${duration}ms ease-in, visibility 0ms linear 0s;

            ${slide &&
            css`
                transform: translateX(0);
                transition: opacity ${duration}ms ease-in, transform ${duration}ms ease-in,
                    visibility 0ms linear 0s;
            `}
        `}
`;

export default FadeOverlayStyles;
