export type Creator = {
    name: string;
    roomName: string;
    joined: string;
    icon: string;
};

export type Quote = {
    name: string;
    quote: string;
    roomName: string;
    icon: string;
};

export type Testimonial = Creator | Quote;

export type UserLove = {
    name: string;
    quote: string;
};

export const testimonials: Testimonial[] = [
    {
        name: 'Dalton Trigg',
        roomName: 'Mavs Step Back',
        icon: '/img/marketing/dalton-trigg.png',
        quote: 'I’ve only hosted two watch parties so far, but I’ve just been so impressed with Playback. I really do feel like this is the future of watching sports.',
    },
    {
        name: 'Nate Duncan',
        roomName: 'Dunc’D on Prime',
        joined: 'May 2022',
        icon: '/img/marketing/duncd-on.png',
    },
    {
        name: 'Mike Golic Jr.',
        roomName: 'Mike Golic Jr.',
        joined: 'April 2022',
        icon: '/img/marketing/mike-golic-jr.png',
    },
    {
        name: 'Kevin O’Connor',
        roomName: 'KOC Streams',
        icon: '/img/marketing/koc.png',
        quote: 'Playback is an amazing platform. And the coolest part is they are just getting started! So much more to come.',
    },
    {
        name: 'The Timeline',
        roomName: 'The Timeline',
        joined: 'December 2021',
        icon: '/img/marketing/the-timeline.png',
    },
    {
        name: 'Half Court Hoops',
        roomName: 'Half Court Hoops',
        joined: 'April 2022',
        icon: '/img/marketing/half-court-hoops.png',
    },
    {
        name: 'Top Rope Nation',
        roomName: 'Top Rope Nation',
        icon: '/img/marketing/top-rope-nation.png',
        quote: 'Can’t put into words how much fun this was. We started streaming Smackdown and then did Rampage too. Playback is an awesome service.',
    },
    {
        name: 'Nekias Duncan and Steve Jones',
        roomName: 'The Dunker Spot',
        joined: 'March 2022',
        icon: '/img/marketing/dunker-spot.png',
    },
    {
        name: 'Mo Dahkil',
        roomName: 'The Jump Ball',
        joined: 'April 2022',
        quote: 'Guys, that was freaking awesome! I had so much fun hosting my first watch party.',
        icon: '/img/marketing/mo-dakhil.png',
    },
];

export const wallOfLove = [
    { name: 'Kabir', quote: 'Thank you guys for doing this! I’ve been here for 10+ hours haha' },
    {
        name: 'dayw33zy',
        quote: 'Thanks as always gentlemen, this is the best thing going on in suns fandom',
    },
    { name: 'belroy 89', quote: 'I absolutely love this' },
    { name: 'Coffee Black', quote: 'these playback games are the fuckin best' },
    { name: 'james', quote: 'playback makes crappy games fun' },
    { name: 'lowmess', quote: 'its a product ive wanted for a very long time' },
    { name: 'maia', quote: 'yesssss playback' },
    { name: 'phxfan88', quote: 'Playback guys: I love you.' },
    { name: 'jgrubb117', quote: 'this stream getting me through work' },
    { name: 'LakerZach', quote: 'im new here and i love every bit of it' },
    {
        name: 'rondizzle',
        quote: 'guys im not gunna lie im less stressed on the playback then i would be just regularly watching so thanks! lol',
    },
    {
        name: 'kenrich williams propaganda',
        quote: 'probably my favorite game watching experience i’ve had - the format is dope',
    },
    {
        name: 'sam',
        quote: 'it’s been too long rohan. you guys had no business disappearing on us with no playback last week.',
    },
];
